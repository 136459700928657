export interface StyleClosetTheme {
  colors: { [key in keyof typeof colors]: string };
  breakpoints: { [key in keyof typeof breakpoints]: string };
}

const colors = {
  primary: '#1b1a20',
  secondary: '#eabf00',
  third: '#bf00ff',
  fifth: 'rgb(174, 212, 200)',/*AED4C8*/
   sixth:  '#E6B89C',
  seventh: '#4E5166',
  eighth: '#363732',
  davy_gray:'#5A5353',
  federal_blue:'#07004D',
  raspberry_red: '#B8336A',
  feldgrau:'#3B5249'
};

const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1025px',
};

const theme: StyleClosetTheme = {
  colors,
  breakpoints,
};

export { theme };
