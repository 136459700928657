import  { useState } from 'react';
import { IItem } from 'interfaces';
import Item from './Item';
import ReactPaginate from 'react-paginate';

import * as S from './style';

interface IProps {
  items: IItem[];
}

const Items = ({ items }: IProps) => {
  const productPerPage = 8;
  const [pageNumber, setPageNumber] = useState(0); 
  const visitedPage = pageNumber * productPerPage;
  const displayPage = items.slice(
    visitedPage,
    visitedPage + productPerPage
  );
  const pageCount = Math.ceil(items.length / productPerPage);
  const changePage = (selectedItem: { selected: number }) => {
    setPageNumber(selectedItem.selected);
  };
  return (
    <S.div2 style={{
      width:'100%',
  }}>
    <S.Container>
      {displayPage?.map((p) => (
        <Item item={p} key={p.sku} />
      ))}
      

    </S.Container>
    
    <ReactPaginate 
      className="paginationBtns"
       pageCount={pageCount}
      onPageChange={changePage}
      previousLabel='Prev'
      nextLabel='Next'
      
  /> 
    </S.div2>
  );
};

export default Items;
