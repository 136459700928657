import { useBuggyContext } from './BuggyContextProvider';
import { IBuggyItem } from 'interfaces';

const useBuggyCost = () => {
  const { total, setTotal } = useBuggyContext();

  const updateBuggyCost = (items: IBuggyItem[]) => {
    const itemQTY = items.reduce(
      (sum: number, item: IBuggyItem) => {
        sum += item.quantity;
        return sum;
      },
      0
    );

    const totalPrice = items.reduce((sum: number, item: IBuggyItem) => {
      sum += item.price * item.quantity;
      return sum;
    }, 0);

    const installments = items.reduce(
      (greater: number, item: IBuggyItem) => {
        greater =
          item.installments > greater ? item.installments : greater;
        return greater;
      },
      0
    );

    const total = {
      itemQTY,
      installments,
      totalPrice,
      currencyId: 'USD',
      currencyFormat: '$',
    };

    setTotal(total);
  };

  return {
    total,
    updateBuggyCost,
  };
};

export default useBuggyCost;
