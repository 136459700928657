import styled from 'styled-components/macro';

export const img2= styled.img`
width: 150px;
    height: 150px;
 background-size:contain;
`;
export const footer =styled.footer`

.footerStyle {
width: 100%;
  height: 100%;

  padding-left:0px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: ${({ theme }) => theme.colors.fifth};
}
  .delivery__time-item {
  background: transparent !important;
}
   a.inactive_menu {
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  text-decoration:none;
}
`;