import axios from 'axios';
import { IRetrieveItemsRes } from 'interfaces';

const isProduction = process.env.NODE_ENV === 'production';

export const getItems = async () => {
  let res: IRetrieveItemsRes;


    res = require('assets/json/products.json');
 

  const { items } = res.data || [];

  return items;
};
