import { Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import Men from 'components/Pages/Men';
import Women from 'components/Pages/Women';
import Kids from 'components/Pages/Kids';
import Home from 'components/Pages/Home';
import Contact from 'components/Pages/Contact'
import Accessories from 'components/Pages/Accessories';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/home' />} />
      <Route path='/home' element={<Home />} />
      <Route path='/men' element={<Men />} />
      <Route path='/women' element={<Women />} />
      <Route path='/kids' element={<Kids />} />
      <Route path='/accessories' element={<Accessories />} />
      <Route path='/contact' element={<Contact />} />
  
    </Routes>
  );
};

export default Router;