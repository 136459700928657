import { useItems } from 'App_CTX/context_Item';

import * as S from './style';

export const sizes = ['XS', 'S', 'M', 'ML', 'L', 'XL', 'XXL','XXXL'];

const Filter = () => {
  const { filters, searchTerm,filterItems,searchItems } = useItems();

  const selectedToggles = new Set(filters);

  const setToggle = (label: string) => {
    if (selectedToggles.has(label)) {
      selectedToggles.delete(label);
    } else {
      selectedToggles.add(label);
    }

    const filters = Array.from(selectedToggles) as [];

    //filterItems(filters,false);
    searchItems(searchTerm,filters)
  };

  const createToggle = (label: string) => (
    <S.sizeTG label={label} handleOnChange={setToggle} key={label} />
  );

  const createToggles = () => sizes.map(createToggle);

  
  return (
    <S.Container>
      <S.Title>Sizes:</S.Title>
      <div style={{margin:'0,100px'}}>
      {createToggles()}
      </div>
    </S.Container>
  );
};

export default Filter;
