import { useBuggyContext } from './BuggyContextProvider';
import useBuggyCost from './useBuggyCost';
import { IBuggyItem } from 'interfaces';

const useBuggyItems = () => {
  const { items, setItems } = useBuggyContext();
  const { updateBuggyCost } = useBuggyCost();

  const safeUpdateQTY = (
    curItem: IBuggyItem,
    tgtItem: IBuggyItem,
    quantity: number
  ): IBuggyItem => {
    if (curItem.id === tgtItem.id) {
      return Object.assign({
        ...curItem,
        quantity: curItem.quantity + quantity,
      });
    } else {
      return curItem;
    }
  };

  const addItem = (itemAdd: IBuggyItem) => {
    let updatedItems;
    const isItemInBuggy = items.some(
      (item: IBuggyItem) => itemAdd.id === item.id
    );

    if (isItemInBuggy) {
      updatedItems = items.map((item: IBuggyItem) => {
        return safeUpdateQTY(item, itemAdd, itemAdd.quantity);
      });
    } else {
      updatedItems = [...items, itemAdd];
    }

    setItems(updatedItems);
    updateBuggyCost(updatedItems);
  };

  const removeItem = (itemRMV: IBuggyItem) => {
    const updatedItems = items.filter(
      (item: IBuggyItem) => item.id !== itemRMV.id
    );

    setItems(updatedItems);
    updateBuggyCost(updatedItems);
  };

  const incItemQTY = (itemINC: IBuggyItem) => {
    const updatedItems = items.map((item: IBuggyItem) => {
      return safeUpdateQTY(item, itemINC, +1);
    });

    setItems(updatedItems);
    updateBuggyCost(updatedItems);
  };

  const decItemQTY = (itemDEC: IBuggyItem) => {
    const updatedItems = items.map((item: IBuggyItem) => {
      return safeUpdateQTY(item, itemDEC, -1);
    });

    setItems(updatedItems);
    updateBuggyCost(updatedItems);
  };

  return {
    items,
    addItem,
    removeItem,
    incItemQTY,
    decItemQTY,
  };
};

export default useBuggyItems;
