import { createContext, useContext, FC, useState } from 'react';

import { IItem } from 'interfaces';

export interface IItemsContext {
  isLoading: boolean;
  setIsLoading(state: boolean): void;
  items: IItem[];
  setProducts(items: IItem[]): void;
  filters: string[];
  setFilters(filters: string[]): void;
  searchTerm: string ;
  setSearchTerm(searchTerm: string): void;
  sorting:string;
  setSorting(sorting:string):void;
}

const ItemsContext = createContext<IItemsContext | undefined>(undefined);
const useItemsContext = (): IItemsContext => {
  const context = useContext(ItemsContext);

  if (!context) {
    throw new Error(
      'useItemsContext must be used within a ItemsProvider'
    );
  }

  return context;
};

const ItemsProvider: FC = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setProducts] = useState<IItem[]>([]);
  const [filters, setFilters] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sorting, setSorting] = useState<string>('');

  const ItemContextValue: IItemsContext = {
    isLoading,
    setIsLoading,
    items,
    setProducts,
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    sorting,
    setSorting,
  };

  return <ItemsContext.Provider value={ItemContextValue} {...props} />;
};

export { ItemsProvider, useItemsContext };
