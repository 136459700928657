import React,{useEffect,useRef} from "react";
import { NavLink, Link } from 'react-router-dom';
import * as S from './style';
import logo from '../../assets/store_logo_3.png'
const navi_links = [
  {
    display: 'Home',
    path: '/home',
  },
  {
    display: 'Men',
    path: '/men',
  },
  {
    display: 'Women',
    path: '/women',
  },
  {
    display: 'Kids',
    path: '/kids',
  },
  {
    display: 'Accessories',
    path: '/accessories',
  },
  {
    display: 'Contact',
    path: '/contact',
  },
 
];

const Header =()=>{
  const headerRef=useRef<HTMLDivElement| null>(null);
    //const headerRef=useRef<HTMLInputElement | null>(null);
    useEffect(()=>{
        document.addEventListener('scroll', () => {
            if (
              document.body.scrollTop >80 ||
              document.documentElement.scrollTop > 80
            ) {
             // if(headerRef.current!=null)
              headerRef.current!.classList.add('header_shrink');
            } else {
            //  if(headerRef.current!=null)
              headerRef.current!.classList.remove('header_shrink');
            }
          });
      //return () => document.removeEventListener('scroll', headerRef);
    },[]);
    return(

          <header className='header' ref={headerRef}>
            <S.Container >
            <img src={logo} alt='logo'  />
            <div className=' d-flex  align-items-center gap-5 '>
            {navi_links.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className={(navClass) =>
                    navClass.isActive ? 'active__menu' : 'inactive_menu'
                  }
                >
                  {item.display}
                </NavLink>
              ))}
           </div>

            </S.Container>
            </header>
        
    );
};

export default Header;/**export 确保Header是一个module */


/**
 * import * as R from '../App/style';

 */