import styled from 'styled-components/macro';

export const Container = styled.div`
overflow:visible;
height:100%
.header {
  width: 100%;
  height: 100px;
  line-height: 100px;
}
.header_shrink{
  position: sticky;
  top: 0;
  left: 0;
  align-self: flex-start;
  background: #fff;
  z-index: 99;
  box-shadow: 5px 5px 15px -5px ${({ theme }) => theme.colors.sixth};
}

 .slider-container{
 width: 100%;
 height: 100%;
   justify:center;
 align-items:center;
 display:inline-block;
 background-color:black;
 margin:0px,100px,0,100px;
 }

  .slider_1{
 width: 350px;
 height: 100%;
  margin-right:0px;
   
   justify:center;
 align-items:center;
 background:yellow;

 }
 .slider_2{
 width: 350px;
 height: 100%;
   
   justify:center;
 align-items:center;
position:relative;
 }

 .img_1
 {
  width: 300px;
 height: 400px;
 background-size:contain;
border-radius: 10px;
}

.slider_2:hover .btn-3
 {
background-color:transparent;
color:black;
opacity:1;
}

.nxtArw
{
width: 100px;
 height: 100px;
 background:blue;
}

.btn-3{
position:absolute;
top:70%;
left:25%;
background-color:transparent;
font-weight:bold;
font-size: 1rem;
border: 2px dotted;
border-color:white;
border-radius: 0;
 cursor: pointer;
 opacity:0;
}

hr.style1{
margin-top:30px;
	border-top: 3px double #8c8b8b;
  width:100vp;
}

.section {
  background: linear-gradient(#212245b2, #212245b2),
    url(${require('assets/mall_banner.jpg')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:150px;
  position:relative;
  margin-top:2px;
}

.section_2 {
  background: 
    url(${require('assets/contact_bg1.webp')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:600px;
  position:relative;
  margin-top:10px;
}
.tittle-type-1 {
  position:absolute;
top:45px;
left:150px;
font-size: 2rem;
font-weight:bold;
color:white;
}
`;



export const TwoColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 4fr;
    margin-top: 20px;
  }
`;

export const Side = styled.div`
  display: grid;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const Main = styled.main``;

export const MainHeader = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  padding: 0 15px;
`;
export const Container2 = styled.div`
position:fixed;
`;
export const searchWgt = styled.div`
display:flex;
height:2rem;
border:1px solid ${({ theme }) => theme.colors.fifth};
 border-radius: 5px;

 &:focus-within{
 outline: 1px solid ${({ theme }) => theme.colors.fifth};;
 }
input {
 padding: 7px 15px;
 border:none;
 }
input:focus {
  outline:none;
}
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  input {
    width: 100%;
    font-size: 0.8rem;
  }
}
`;
export const sortWgt = styled.div`
display:flex;
width:12rem;
height:2rem;
margin:5px;


select {
  padding: 1px 0px;
  border-radius: 5px;
 border:1px solid ${({ theme }) => theme.colors.fifth};
  cursor: pointer;
   font-size: 0.8rem;
}

  @media only screen and (max-width: 768px) {
 select {
    color: #777;
    width: 100% !important;
    font-size: 0.8rem;
  }
}
`;
export const Container3 = styled.div`
`;
/**
 *  .slide-1 {
 width: 10px;
  height: 100%;
 background-size:contain;
  justify:center;
 align-items:center;
 margin：20px；
 background:yellow;
 }

 margin:30px 200px 30px 200px;
 */