import { useCallback } from 'react';

import { useItemsContext } from './ItemsContextProvider';
import { IItem } from 'interfaces';
import { getItems } from 'data-factory/items';

const useItems = () => {
  const {
    isLoading,
    setIsLoading,
    items,
    setProducts,
    filters,
    setFilters,
    searchTerm,
    setSearchTerm,
    sorting,
    setSorting,
  } = useItemsContext();

  const loadItems = useCallback(() => {
    setIsLoading(true);
    getItems().then((items: IItem[]) => {
      setIsLoading(false);
      setProducts(items);
    });
  }, [setIsLoading, setProducts]);

  const filterItems = (filters: string[],isFreeShipping:Boolean) => {
    setIsLoading(true);

    getItems().then((items: IItem[]) => {
      setIsLoading(false);
      let filteredProducts;

      if (filters && filters.length > 0) {
        filteredProducts = items.filter((p: IItem) =>
          filters.find((filter: string) =>
            p.availableSizes.find((size: string) => size === filter)
          )
        );
      } else {
        filteredProducts = items;
      }

      filteredProducts.filter(item=>item.isFreeShipping==isFreeShipping);

      setFilters(filters);
      setProducts(filteredProducts);
    });
  };

  const searchItems =(searchTerm2:string,filters: string[])=>
  {
    setIsLoading(true);
    getItems().then((items: IItem[]) => {
      setIsLoading(false);
    let searchedProducts;
    let filteredProducts;
    searchedProducts =items.filter((item)=>{
    if(searchTerm2=='')
      {
        return item;
      }else if(item.title.includes(searchTerm2))
        {
          return item;
        }
  })
  if (filters && filters.length > 0) {
    filteredProducts = searchedProducts.filter((p: IItem) =>
      filters.find((filter: string) =>
        p.availableSizes.find((size: string) => size === filter)
      )
    );
  } else {
    filteredProducts = searchedProducts;
  }
  setFilters(filters);
  setSearchTerm(searchTerm2);
      setProducts(filteredProducts);
    });
};

  const sortItems =(sorting:string)=>{
    if(sorting =='ascending')
      {
        items.sort((a,b) =>a.title > b.title ?1:-1);
      }else  if(sorting =='descending')
      {
       items.sort((a,b) =>a.title > b.title ?-1:1);
      }else  if(sorting =='high-price')
      {
       items.sort((a,b) =>b.price - a.price);
      }else  if(sorting =='low-price')
      {
       items.sort((a,b) =>a.price -b.price);
      }
      setSorting(sorting);
  }

  return {
    isLoading,
    loadItems,
    items,
    filterItems,
    filters,
    searchTerm,
    setSearchTerm,
    searchItems,
    sortItems,
    setSorting,
  };
};

export default useItems;
