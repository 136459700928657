import formatPrice from 'tools/formatPrice';
import BuggyItems from './BuggyItems';

import { useBuggy } from 'App_CTX/context_buggy';

import * as S from './style';

const Buggy = () => {
  const { items, total, isShow, showBuggy, hideBuggy } = useBuggy();

  const handleCheckout = () => {
    if (total.itemQTY) {
      alert(
        `Checkout - Subtotal: ${total.currencyFormat} ${formatPrice(
          total.totalPrice,
          total.currencyId
        )}`
      );
    } else {
      alert('Add some product in the cart!');
    }
  };

  const handleShowBuggy = (isShow: boolean) => () =>
    isShow ? hideBuggy() : showBuggy();

  return (
    <S.Container isShow={isShow}>
      <S.BuggyBtn onClick={handleShowBuggy(isShow)}>
        {isShow ? (
          <span>X</span>
        ) : (
          <S.BuggyIcon>
            <S.BuggyQTY title="Products in cart quantity">
              {total.itemQTY}
            </S.BuggyQTY>
          </S.BuggyIcon>
        )}
      </S.BuggyBtn>

      {isShow && (
        <S.BuggyBox>
          <S.BuggyHeader>
            <S.BuggyIcon large>
              <S.BuggyQTY>{total.itemQTY}</S.BuggyQTY>
            </S.BuggyIcon>
            <S.HeaderTitle>Buggy</S.HeaderTitle>
          </S.BuggyHeader>

          <BuggyItems products={items} />

          <S.BuggyFooter>
            <S.Sub>TOTAL</S.Sub>
            <S.SubPrice>
              <S.SubPriceValue>{`${total.currencyFormat} ${formatPrice(
                total.totalPrice,
                total.currencyId
              )}`}</S.SubPriceValue>
              <S.SubPriceInstallment>
                {total.installments ? (
                  <span>
                    {`OR UP TO ${total.installments} x ${
                      total.currencyFormat
                    } ${formatPrice(
                      total.totalPrice / total.installments,
                      total.currencyId
                    )}`}
                  </span>
                ) : null}
              </S.SubPriceInstallment>
            </S.SubPrice>
            <S.CheckoutButton onClick={handleCheckout} autoFocus>
             Continue
            </S.CheckoutButton>
          </S.BuggyFooter>
        </S.BuggyBox>
      )}
    </S.Container>
  );
};

export default Buggy;
