import React, { useEffect } from 'react';

import Loader from 'components/Loader';
import { Items } from 'components/Items';
import Buggy from 'components/Buggy';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Router from 'components/route/Router';
import { useItems } from 'App_CTX/context_Item';
import * as S from './style';

function App() {
  const { isLoading, loadItems} = useItems();
  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <S.Container>
      <Header />
      {isLoading && <Loader />}
      <Router />
      <Buggy />
      <Footer />
    </S.Container>
  );
}

export default App;
