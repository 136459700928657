import formatPrice from 'tools/formatPrice';
import { IBuggyItem } from 'interfaces';

import { useBuggy } from 'App_CTX/context_buggy';

import * as S from './style';

interface IProps {
  item: IBuggyItem;
}
const BuggyItem = ({ item }: IProps) => {
  const { removeItem, incItemQTY, decItemQTY } =
    useBuggy();
  const {
    sku,
    title,
    price,
    style,
    currencyId,
    currencyFormat,
    availableSizes,
    quantity,
  } = item;

  const handleRMVItem = () => removeItem(item);
  const handleINCItem = () => incItemQTY(item);
  const handleDECItem = () => decItemQTY(item);

  return (
    <S.Container>
      <S.DeleteButton
        onClick={handleRMVItem}
        title="remove item from cart"
      />
      <S.Image
        src={require(`assets/items/${sku}-1-cart.webp`)}
        alt={title}
      />
      <S.Details>
        <S.Title>{title}</S.Title>
        <S.Desc>
          {`${availableSizes[0]} | ${style}`} <br />
          Quantity: {quantity}
        </S.Desc>
      </S.Details>
      <S.Price>
        <p>{`${currencyFormat}  ${formatPrice(price, currencyId)}`}</p>
        <div>
          <S.ChangeQuantity
            onClick={handleDECItem}
            disabled={quantity === 1 ? true : false}
          >
            -
          </S.ChangeQuantity>
          <S.ChangeQuantity onClick={handleINCItem}>
            +
          </S.ChangeQuantity>
        </div>
      </S.Price>
    </S.Container>
  );
};

export default BuggyItem;
