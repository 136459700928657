import React, { useEffect } from 'react';
import Filter from 'components/Filter';
import {Items} from 'components/Items';
import * as R from '../App/style';

import { useItems } from 'App_CTX/context_Item';
function Men() {
   
    const { isLoading, items,filters,searchTerm, loadItems, setSearchTerm,searchItems,sortItems} = useItems();
    const menGoods = items.filter((e)=>e.sex==1);
    return(
      <div>
        <section className='section'>
          <h1 className='tittle-type-1'>Men</h1>
        </section>
        <R.TwoColumnGrid>
        <R.Side>
              <R.Container3>
          <R.searchWgt>
          <input
                  type='text'
                  placeholder="I'm looking for..."
                  value={searchTerm}
                  onChange={(e) => searchItems(e.target.value,filters)}
                />
                <span>
                  <i className='ri-search-line'></i>
                </span>
          </R.searchWgt>
          <R.sortWgt>
                <select className='w-50' onChange={(e) =>sortItems(e.target.value)}>
                  <option>OrderBy</option>
                  <option value='ascending'>Alphabetically, A-Z</option>
                  <option value='descending'>Alphabetically, Z-A</option>
                  <option value='high-price'>High price</option>
                  <option value='low-price'>Low price</option>
                </select>
              </R.sortWgt>
              </R.Container3>
        
        </R.Side>
        <R.Main>
        <Filter />
          <R.MainHeader>
            <p>{menGoods?.length} Men item(s) listed below</p>
          </R.MainHeader>
          <Items items={menGoods} />
        </R.Main>
      </R.TwoColumnGrid>
      </div>
    )
}

export default Men;