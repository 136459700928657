import styled from 'styled-components/macro';
import logo from '../../assets/shopping_0.png'

export const BrokenGrid= styled.div`
display: grid;
margin-left:0px;
margin-top:10px;
place-self:center;
grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap:1vw 1vw  ;
  
  .item-1 {
  
  background-size:contain;
  grid-row-start: 1;
  grid-row-end: 3;
    grid-column-start: 1;
  grid-column-end: 3;
 
  width:900px;
  height:900px;
  justify:center;
}


  .item-2 {
  width:440px;
  height:440px;
 justify:center;
 align-items:center;
}
 
 .item-11 {
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size:cover;
  grid-row-start: 1;
  grid-row-end: 3;
    grid-column-start: 1;
  grid-column-end: 3;
 
  width:40vw;
  height:40vw;
text-align:center;
position:relative;
}

.item-22 {
background-repeat: no-repeat;
background-size:cover;
  width:19.5vw;
  height:19.5vw;
 justify:center;
 align-items:center;
 padding:0px;
 margin:0px;
 position:relative;
}

.hover-layer
{
 width:18vw;
  height:18vw;
  background-color:black;
   position:absolute;
opacity:0;
top:0.8vw;
left:0.8vw;
}

.item-22:hover .hover-layer{
opacity:0.8;

}

.btn-1{
position:absolute;
top:26vw;
left:26vw;
background-color:transparent;
font-weight:bold;
font-size: 1.5rem;
border: 2px dotted;
border-color:white;
border-radius: 0;
cursor: pointer;
}

.btn-1:hover{
background-color:white;
color:black;
 
}



.btn-2{
position:absolute;
top:13vw;
left:8vw;
background-color:transparent;
font-weight:bold;
font-size: 1.5rem;
border: 2px dotted;
border-color:white;
border-radius: 0;
 cursor: pointer;
}

.btn-2:hover{
background-color:white;
color:black;

}



.tittle-1{
position:absolute;
top:11vw;
left:2vw;
font-size: 4.2rem;
font-weight:bold;
color:white;
}
.tittle-2{
position:absolute;
top:20vw;
left:2vw;
font-size: 2rem;
font-weight:bold;
font-style:italic;
color:white;
}
.tittle-3{
position:absolute;
top:5vw;
left:6vw;
font-size: 2rem;
font-weight:bold;
color:white;
}
.tittle-4{
position:absolute;
top:9vw;
left:2vw;
font-size: 1rem;
font-weight:bold;
color:white;
}
`;
//grid-column-start: 2;  background:#df2020; background:#4c2c10;
//background-image: url(${logo});

 // grid-column-end: 4;