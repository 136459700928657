import styled from 'styled-components/macro';


export const Container = styled.div`
  display:flex;
  top: 0%;
  left: 0%;
  margin-left: 0px;
  margin-top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  
  background-color:  ${({ theme }) => theme.colors.fifth};
  float:false;
  justify-content:center;
  h5{
  font-size: 22px;
  text-align: center;
  padding-left: 80px;
  }
  img{
  height:100px;
  padding-right:5vw;
  }
  a.inactive_menu {
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  text-decoration:none;
}

 a:hover {
  color: ${({ theme }) => theme.colors.raspberry_red};
  cursor: pointer;
}


  
a.active__menu {
font-weight: 600;
font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.raspberry_red};
   transition: 0.3s;
  text-decoration:none;
}


@media only screen and (max-width: 992px) {
  .logo img {
    width: calc(100% - 70%);
  }

  .logo h5 {
    font-size: 0.8rem;
    margin-top: -15px;
  }

  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
    display: none;
  }

 

  .menu a {
    font-size: 0.9rem;
  }

  .active__menu {
  color: ${({ theme }) => theme.colors.raspberry_red};
}

  .mobile__menu {
    display: block;
  }

  .cart__icon i,
  .user i,
  .mobile__menu i {
    font-size: 1rem;
  }

  .show__menu {
    display: block;
  }
}
`;
//border-radius: 20px;