import { useBuggyContext } from './BuggyContextProvider';
import useCartProducts from './useBuggyItems';
import useBuggyCost from './useBuggyCost';

const useBuggy = () => {
  const { isShow, setIsShow } = useBuggyContext();
  const {
    items,
    addItem,
    removeItem,
    incItemQTY,
    decItemQTY,
  } = useCartProducts();
  const { total, updateBuggyCost } = useBuggyCost();

  const showBuggy = () => setIsShow(true);
  const hideBuggy = () => setIsShow(false);

  return {
    isShow,
    showBuggy,
    hideBuggy,
    items,
    addItem,
    removeItem,
    incItemQTY,
    decItemQTY,
    total,
    updateBuggyCost,
  };
};

export default useBuggy;
