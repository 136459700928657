import { createContext, useContext, FC, useState } from 'react';
import { IBuggyItem, IBuggyCost } from 'interfaces';

export interface IBuggyContext {
  isShow: boolean;
  setIsShow(state: boolean): void;
  items: IBuggyItem[];
  setItems(items: IBuggyItem[]): void;
  total: IBuggyCost;
  setTotal(items: any): void;
}

const BuggyContext = createContext<IBuggyContext | undefined>(undefined);
const useBuggyContext = (): IBuggyContext => {
  const context = useContext(BuggyContext);

  if (!context) {
    throw new Error('useBuggyContext must be used within a BuggyProvider');
  }

  return context;
};

const totalInitialValues = {
  itemQTY: 0,
  installments: 0,
  totalPrice: 0,
  currencyId: 'USD',
  currencyFormat: '$',
};

const BuggyProvider: FC = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [items, setItems] = useState<IBuggyItem[]>([]);
  const [total, setTotal] = useState<IBuggyCost>(totalInitialValues);

  const BuggyContextValue: IBuggyContext = {
    isShow,
    setIsShow,
    items,
    setItems,
    total,
    setTotal,
  };

  return <BuggyContext.Provider value={BuggyContextValue} {...props} />;
};

export { BuggyProvider, useBuggyContext };
