import { StrictMode } from 'react';
//import * as ReactDOMClient from 'react-dom/client';
import ReactDOM from 'react-dom';
 
/* Theme */
import { ThemeProvider } from 'commons/style/styled-components';
import { theme } from 'commons/style/theme';
import GlobalStyle from 'commons/style/global-style';

/* Context Providers */
import { ItemsProvider } from 'App_CTX/context_Item';
import { BuggyProvider } from 'App_CTX/context_buggy';

import 'remixicon/fonts/remixicon.css';

import { BrowserRouter as Router } from 'react-router-dom';
import App from 'components/App';

//const root = document.getElementById('root')!;
//const container = ReactDOMClient.createRoot(root);

ReactDOM.render(
  <StrictMode>
    <Router>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ItemsProvider>
        <BuggyProvider>
          <App />
        </BuggyProvider>
      </ItemsProvider>
    </ThemeProvider>
    </Router>
  </StrictMode>,
  document.getElementById('root')
);
